import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import { FiArrowUp } from 'react-icons/fi';
import ContentRow from "../../../../src/components/ContentRow.js";
import Sidenote from "../../../../src/components/Sidenote.js";
import { IconContext } from 'react-icons';
import Text from "../../../../src/components/Text.js";
import Mheader from "../../../../src/images/Met/Header.png";
import MLogoOld from "../../../../src/images/Met/LogoCurrent.png";
import MMoodboard from "../../../../src/images/Met/Moodboard.png";
import MProb1 from "../../../../src/images/Met/prob1.png";
import MProb2 from "../../../../src/images/Met/prob2.png";
import MProb3 from "../../../../src/images/Met/prob3.png";
import MProb4 from "../../../../src/images/Met/prob4.png";
import MBr1 from "../../../../src/images/Met/br1.png";
import MBr2 from "../../../../src/images/Met/br2.png";
import MType from "../../../../src/images/Met/Typography.png";
import MLogo from "../../../../src/images/Met/Logotype.png";
import MBranding from "../../../../src/images/Met/Branding.png";
import MSketches from "../../../../src/images/Met/Sketches.png";
import MIter1 from "../../../../src/images/Met/Iter1.png";
import MCP from "../../../../src/images/Met/CasualPatron.png";
import MCasual from "../../../../src/images/Met/Casual.png";
import MPatron from "../../../../src/images/Met/Patron.png";
import MNonDig from "../../../../src/images/Met/NonDig.png";
import * as React from 'react';
export default {
  FiArrowUp,
  ContentRow,
  Sidenote,
  IconContext,
  Text,
  Mheader,
  MLogoOld,
  MMoodboard,
  MProb1,
  MProb2,
  MProb3,
  MProb4,
  MBr1,
  MBr2,
  MType,
  MLogo,
  MBranding,
  MSketches,
  MIter1,
  MCP,
  MCasual,
  MPatron,
  MNonDig,
  React
};