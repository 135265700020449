import * as React from 'react'
import Text from './Text'

const Blockquote = ({title, text}) => {
    return(
        <div class="quote-base">
            <div class="quote-content text">
               <i><Text text={text}></Text></i>
            </div>
        </div>
    )
}

export default Blockquote