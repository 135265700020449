import Sheader from "../../../../src/images/SPX/Header.png";
import fonts from "../../../../src/images/SPX/Fonts.png";
import colors from "../../../../src/images/SPX/Colors.png";
import buttons from "../../../../src/images/SPX/Buttons.png";
import appbars from "../../../../src/images/SPX/App Bars.png";
import dialogs from "../../../../src/images/SPX/Dialogs.png";
import controls from "../../../../src/images/SPX/Controls.png";
import textfields from "../../../../src/images/SPX/TextFields.png";
import cards from "../../../../src/images/SPX/Cards.png";
import progress from "../../../../src/images/SPX/Progress Bars.png";
import toasts from "../../../../src/images/SPX/Toasts.png";
import final from "../../../../src/images/SPX/Final 2.png";
import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import ContentRow from "../../../../src/components/ContentRow.js";
import Sidenote from "../../../../src/components/Sidenote.js";
import * as React from 'react';
export default {
  Sheader,
  fonts,
  colors,
  buttons,
  appbars,
  dialogs,
  controls,
  textfields,
  cards,
  progress,
  toasts,
  final,
  ContentRow,
  Sidenote,
  React
};