import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import ContentRow from "../../../../src/components/ContentRow.js";
import Sidenote from "../../../../src/components/Sidenote.js";
import { IconContext } from 'react-icons';
import Text from "../../../../src/components/Text.js";
import Gheader from "../../../../src/images/Gmail/Header.png";
import textIcon from "../../../../src/images/Gmail/icons/text.redaction.png";
import tagIcon from "../../../../src/images/Gmail/icons/tag.circle.png";
import messageIcon from "../../../../src/images/Gmail/icons/message.bubble.png";
import GMoodboard from "../../../../src/images/Gmail/Moodboard.png";
import GType from "../../../../src/images/Gmail/Typography.png";
import GDS from "../../../../src/images/Gmail/Design System.png";
import G1preview from "../../../../src/images/Gmail/Gmail1.png";
import G2preview from "../../../../src/images/Gmail/Gmail2.png";
import G3preview from "../../../../src/images/Gmail/Gmail3.png";
import GTesla from "../../../../src/images/Gmail/TeslaMockup.png";
import GNonDig from "../../../../src/images/Gmail/NonDig.png";
import * as React from 'react';
export default {
  ContentRow,
  Sidenote,
  IconContext,
  Text,
  Gheader,
  textIcon,
  tagIcon,
  messageIcon,
  GMoodboard,
  GType,
  GDS,
  G1preview,
  G2preview,
  G3preview,
  GTesla,
  GNonDig,
  React
};