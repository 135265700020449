import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import ContentRow from "../../../../src/components/ContentRow.js";
import Blockquote from "../../../../src/components/Blockquote.js";
import { IconContext } from 'react-icons';
import Text from "../../../../src/components/Text.js";
import IDheader from "../../../../src/images/InfoDesign/Header.png";
import PAMaps from "../../../../src/images/InfoDesign/PerceptionMap.png";
import GAMaps from "../../../../src/images/InfoDesign/GeneralMap.png";
import IDPersonas from "../../../../src/images/InfoDesign/Personas.png";
import IDPersonasVivian from "../../../../src/images/InfoDesign/Persona-Vivian.png";
import IDPersonasSimon from "../../../../src/images/InfoDesign/Persona-Simon.png";
import IDPersonasAdam from "../../../../src/images/InfoDesign/Persona-Adam.png";
import IDRelationshipMost from "../../../../src/images/InfoDesign/Relationship1.png";
import IDRelationshipVery from "../../../../src/images/InfoDesign/Relationship2.png";
import IDRelationshipSomewhat from "../../../../src/images/InfoDesign/Relationship3.png";
import IDConcept1 from "../../../../src/images/InfoDesign/Concept1.png";
import IDConcept2 from "../../../../src/images/InfoDesign/Concept2.png";
import IDConcept3 from "../../../../src/images/InfoDesign/Concept3.png";
import IDConcept4 from "../../../../src/images/InfoDesign/Concept4.png";
import IDConcept5 from "../../../../src/images/InfoDesign/Concept5.png";
import IDBrandGuide from "../../../../src/images/InfoDesign/BrandGuidelineThumbnail.png";
import IDBrandGuidePDF from "../../../../src/static/IDBrandGuide.pdf";
import * as React from 'react';
export default {
  ContentRow,
  Blockquote,
  IconContext,
  Text,
  IDheader,
  PAMaps,
  GAMaps,
  IDPersonas,
  IDPersonasVivian,
  IDPersonasSimon,
  IDPersonasAdam,
  IDRelationshipMost,
  IDRelationshipVery,
  IDRelationshipSomewhat,
  IDConcept1,
  IDConcept2,
  IDConcept3,
  IDConcept4,
  IDConcept5,
  IDBrandGuide,
  IDBrandGuidePDF,
  React
};