import * as React from 'react'
import Text from './Text'

const Sidenote = ({title, text}) => {
    return(
        <div class="sidenote-base">
            <strong class="sidenote-title">{title}</strong>
            <div class="sidenote-content text">
               <Text text={text}></Text>
            </div>
        </div>
    )
}

export default Sidenote